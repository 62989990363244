var settings = {};

export function store( obj )
{
	settings = obj;
} 


export function restore( )
{
	return settings;
} 